import React, { useEffect, useState, useRef } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Checkbox from 'components/shared/checkbox';
import GenerateInfoButton from './generateInfoButton';


import { CONTENT_TYPE } from 'components/constants';

const InformationSection = (props) => {
	const {
		name: _name,
		youtubeTitle: _youtubeTitle,
		isYoutubeTitleSet: _isYoutubeTitleSet,
		isNameSet: _isNameSet,
		episodeNo: _episodeNo,
		season: _season,
		explicit: _explicit,
		type: _type,
		onChange = () => {},
		canEdit,
		isHosted,
		episodeContentType,
		episodeId,
		accountId,
		editsMade,
		generatingAll,
		setInfoSectionAllGenerated,
		disableGenerateButton
	} = props;

	const { featureAiSummarySuggestionAccess } = useFlags();

	const [editName, setEditName] = useState(_name);
	const [editType, setEditType] = useState(_type);
	const [editSeasonNumber, setEditSeasonNumber] = useState(_season || '');
	const [editEpisodeNumber, setEditEpisodeNumber] = useState(_episodeNo);
	const [explicit, setExplicit] = useState(_explicit);

	// generating states
	const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
	const [titleIsGenerated, setTitleIsGenerated] = useState(false);
	const [showTitleGenerateError, setShowTitleGenerateError] = useState(false);
	const [customErrorMessage, setCustomErrorMessage] = useState(false);
	const [youtubeTitle, setYoutubeTitle] = useState(_youtubeTitle);
	const [isEditingYoutubeTitle, setIsEditingYoutubeTitle] = useState(false);
	const [cachedYoutubeTitle, setCachedYoutubeTitle] = useState('');
	const syncStopped = useRef(false);
	const syncStoppedPermanently = useRef(false);

	useEffect(() => {
		if(_isYoutubeTitleSet){
			return
		}
		if (syncStoppedPermanently.current) return;
		const isOverLimit = editName?.length > 100;
		if (!isEditingYoutubeTitle) {
			const truncatedTitle = isOverLimit ? editName.slice(0, 100) : editName;
			setYoutubeTitle((!_isYoutubeTitleSet && _isNameSet  ) ? "" :truncatedTitle);
		}	
		syncStopped.current = isOverLimit;
	}, [editName, isEditingYoutubeTitle]);
	
	const saveYoutubeTitle = () => {		
		setIsEditingYoutubeTitle(false);
		setCachedYoutubeTitle(youtubeTitle);
		syncStoppedPermanently.current = true;
	};

	const cancelYoutubeEdit = () => {
		setYoutubeTitle(cachedYoutubeTitle || editName);
		setIsEditingYoutubeTitle(false);
	};

	const [generateItemTitle] = useMutation(mutations.generateEpisodeTitle, {
		onError: (err) => {
			console.error(err);
			if(err?.message === 'RateLimitError'){
				setCustomErrorMessage('Our AI is a bit busy right now, try again in a few minutes')
			}
			setShowTitleGenerateError(true);
		}
	});

	useEffect(() => {
		if (generatingAll) {
			generateTitle();
		}
	}, [generatingAll])

	useEffect(() => {
		if (showTitleGenerateError) {
			setTimeout(() => {
				setShowTitleGenerateError(false);
			}, 5000);
		}
	}, [showTitleGenerateError]);


	useEffect(() => {
		if (editName !== _name) {
			onChange('name', editName);
		}
	}, [editName]);

	useEffect(() => {
		if (editType !== _type) {
			onChange('type', editType);
		}
	}, [editType]);

	useEffect(() => {
		if (youtubeTitle !== _youtubeTitle) {
			onChange('youtubeTitle', youtubeTitle);
		}
	}, [youtubeTitle]);

	useEffect(() => {
		// At least one value must be non-null, non-0, non-empty string
		// This is a required check since the season/episode component can't have a null value but the db can
		if (editSeasonNumber !== _season && (editSeasonNumber || _season)) {
			let seasonInt = null;
			if (editSeasonNumber !== '' && !isNaN(editSeasonNumber)) {
				seasonInt = parseInt(editSeasonNumber, 10);
			}
			onChange('season', seasonInt);
		}
	}, [editSeasonNumber]);

	// At least one value must be non-null, non-0, non-empty string,
	// Exception ! if type is bonus or trailer, value must be null
	useEffect(() => {
		if (editEpisodeNumber !== _episodeNo) {
			if ((editEpisodeNumber || _episodeNo) && editType === 'full') {
				let episodeInt;
				if (editEpisodeNumber !== '' && !isNaN(editEpisodeNumber)) {
					episodeInt = parseInt(editEpisodeNumber, 10);
				}
				onChange('episode', episodeInt);
			} else if (editType !== 'full') {
				onChange('episode', null);
			}
		}
	}, [editEpisodeNumber]);

	useEffect(() => {
		if (explicit !== _explicit) {
			onChange('explicit', explicit);
		}
	});

	const generateTitle = async () => {
		setIsGeneratingTitle(true);
		setTitleIsGenerated(false);
		const { data } = await generateItemTitle({
			variables: {
				episodeId,
				accountId,
			},
		});
		const { generateEpisodeTitle } = data || {};
		if (generateEpisodeTitle) {
			let cleanTitle = generateEpisodeTitle.replace(/['"]+/g, '').trim();
			setEditName(cleanTitle);
			setIsGeneratingTitle(false);
			setTitleIsGenerated(true);
		} else {
			setIsGeneratingTitle(false);
			setShowTitleGenerateError(true);
		}

		setInfoSectionAllGenerated(true);
	};

	return (
		<>
			<Container className="px-2 pb-2">
				<Row className="mb-3">
					<Col>
						<Form.Label className="label">
							{episodeContentType} Title *
						</Form.Label>
						<Form.Control
							disabled={!canEdit || !isHosted}
							value={editName}
							onChange={(e) => {
								setEditName(e.target.value);
							}}
							onBlur={(e) => {
								setEditName(e.target.value.trim());
							}}
							isInvalid={editName?.length > 140}
						/>
						<Form.Control.Feedback type="invalid">
							• Must be 140 characters or less
						</Form.Control.Feedback>
						{featureAiSummarySuggestionAccess && (
							<GenerateInfoButton
								editsMade={editsMade}
								isGeneratingInfo={isGeneratingTitle}
								infoIsGenerated={titleIsGenerated}
								showInfoGenerateError={showTitleGenerateError}
								funcToCall={generateTitle}
								className="simple-input"
								disabled={disableGenerateButton}
								customErrorMessage={customErrorMessage}
								generateMessage = 'Generate an Episode Title'
							/>
						)}
					</Col>
				</Row>

		<Row className="mb-3">
          <Col>
            {canEdit && isHosted && (
              <>
                <Form.Label className="label">YouTube Title</Form.Label>
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <Form.Control
                      disabled={!isEditingYoutubeTitle}
                      value={youtubeTitle}
                      onChange={(e) => setYoutubeTitle(e.target.value)}
                      isInvalid={youtubeTitle?.length > 100}
                    />
                    <Form.Control.Feedback type="invalid">
                      • Must be 100 characters or less
                    </Form.Control.Feedback>
                  </div>

                  {!isEditingYoutubeTitle && (
                    <button
                      className="btn btn-link btn-sm ms-2 mt-1 text-decoration-underline"
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        setIsEditingYoutubeTitle(true);
                        setCachedYoutubeTitle(youtubeTitle);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>

                {isEditingYoutubeTitle && (
                  <div
                    className="mt-2 d-flex"
                    style={{"justify-content": "start" }}
                  >
                    <button
                      className="btn btn-outline-secondary btn-sm mr-2"
                      onClick={cancelYoutubeEdit}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={saveYoutubeTitle}
                      disabled={
                        youtubeTitle.length > 100 || youtubeTitle.length === 0
                      }
                    >
                      Save
                    </button>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>

				{episodeContentType === CONTENT_TYPE.episode && (
					<>
						<Row className="mb-3">
							<Col>
								<Form.Label className="label">Episode Type</Form.Label>
								<Form.Control
									as="select"
									disabled={!canEdit || !isHosted}
									value={editType}
									onChange={(e) => {
										setEditType(e.target.value);
									}}
								>
									<option value="full">Full</option>
									<option value="bonus">Bonus</option>
									<option value="trailer">Trailer</option>
								</Form.Control>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col xs="6">
								<Form.Label className="label">Season</Form.Label>
								<Form.Control
									disabled={!canEdit || !isHosted}
									type="number"
									value={editSeasonNumber}
									onChange={(e) => {
										setEditSeasonNumber(e.target.value);
									}}
								/>
							</Col>
							<Col xs="6">
								<Form.Label className="label">Episode</Form.Label>
								<Form.Control
									disabled={!canEdit || !isHosted || editType !== 'full'}
									type="number"
									value={editType !== 'full' ? null : editEpisodeNumber}
									onChange={(e) => setEditEpisodeNumber(e.target.value)}
								/>
							</Col>
						</Row>
					</>
				)}
				<Row>
					<Col>
						<Form.Label className="label">Explicit Content</Form.Label>
						<div className="explicit-option">
							<Checkbox
								id="check-explicit"
								disabled={!canEdit || !isHosted}
								checked={explicit}
								onChange={(e) => setExplicit(e.target.checked)}
							/>
							<p>Mark {episodeContentType} as explicit for RSS feeds.</p>
						</div>
					</Col>
				</Row>
				<Form.Label className="label">* Required Fields</Form.Label>
			</Container>
		</>
	);
};

export default InformationSection;
